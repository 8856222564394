import LinkButton from 'cms/components/Table/LinkButton';
import FormikField from 'components/FormikField';
import { Field, Form, Formik } from 'formik';
import messages from '../messages';
import React, { useCallback, useState } from 'react';
import UploadPhoto from './UploadPhoto';
import { useDispatch, useSelector } from 'react-redux';
import { createCatalog } from '../redux/actions';
import Buttons from 'cms/components/Formik/Buttons';
import routes from 'routes';
import { makeSelectCreateCatalogtIsPending } from '../redux/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from '../redux/reducer';
import saga from '../redux/saga';

const key = 'catalogs';

export default function CreateCatalog(onSubmit) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const [isChecked, setIsChecked] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedPdfName, setSelectedPdfName] = useState('');
  const dispatch = useDispatch();
  const isPending = useSelector(makeSelectCreateCatalogtIsPending());

  const handleImageChange = event => {
    const selectedImage = event.target.files[0];
    setSelectedImageName(selectedImage.name);
  };
  const handlePdfChange = event => {
    const selectedPdf = event.target.files[0];
    setSelectedPdfName(selectedPdf.name);
  };

  const handleSubmit = useCallback(
    (...args) => {
      dispatch(createCatalog(...args));
    },
    [dispatch]
  );

  return (
    <div className="createCatalogPage">
      <Formik
        initialValues={{ name: '', pdf_file: '', image: '' }}
        onSubmit={(values, { setErrors }) =>
          handleSubmit(values, { setErrors })
        }
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <>
                <FormikField
                  type="text"
                  name="name"
                  label={messages.name}
                  errors={errors}
                />

                <div>
                  <label className="fileLabel" htmlFor="image">
                    {messages.choose_photo.defaultMessage}
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    required
                    onChange={event => {
                      setFieldValue('image', event.currentTarget.files[0]);
                      handleImageChange(event);
                    }}
                  ></input>
                  {selectedImageName && (
                    <p>Izabrana slika: {selectedImageName}</p>
                  )}
                </div>
                <div>
                  <label className="fileLabel" htmlFor="pdf_file">
                    {messages.choose_pdf.defaultMessage}
                  </label>
                  <input
                    type="file"
                    name="pdf_file"
                    id="pdf_file"
                    required
                    onChange={event => {
                      setFieldValue('pdf_file', event.currentTarget.files[0]);
                      handlePdfChange(event);
                    }}
                  ></input>
                  {selectedPdfName && (
                    <p>Izabrani PDF fajl: {selectedPdfName}</p>
                  )}
                </div>

                <Buttons
                  link={routes.CMS_CATALOGS}
                  label={messages.add_catalog}
                  isPending={isChecked ? true : isPending}
                />
                {/* <button type="submit">DODAJ KATALOG</button> */}
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
