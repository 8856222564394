import { call, put, takeLatest } from 'redux-saga/effects';
import types from './constants';
import request from 'utils/request';
import {
  createCatalogErrors,
  createCatalogSuccess,
  createMainPhoto,
  getCatalogs,
  sendPdfSuccess,
  setCatalogs
} from './actions';
import { push } from 'connected-react-router';
import routes from 'routes';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import parseApiErrorsToFormik from 'utils/parseApiErrorsToFormik';

const BASE_URL = 'admin/catalogs';

export function* fetchCatalogs() {
  try {
    console.log('FETCHING CATALOGS');
    const res = yield call(request, {
      url: `${BASE_URL}`,
      method: 'get'
    });
    yield put(setCatalogs(res));
  } catch (error) {}
}

export function* createCatalog({ payload, meta: { setErrors } }) {
  console.log('SAGA PAYLOAD IS', payload);
  try {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('image', payload.image);
    formData.append('pdf_file', payload.pdf_file);

    const res = yield call(request, {
      url: BASE_URL,
      method: 'post',
      data: formData
    });

    if (res) {
      // let id = res.data.product.id;
      // if (pdf_file) yield put(sendPdfSuccess(id, pdf_file));
      // if (image) yield put(createMainPhoto(id, image));
      console.log('INSIDE IF RES!');
      yield put(push(routes.CMS_CATALOGS));
      yield put(createCatalogSuccess());
      yield put(enqueueSnackbar({ message: res.data.message }));
    }
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(createCatalogErrors());
  }
}

export function* deleteCatalog({ payload }) {
  try {
    console.log('DELETE CATALOG PAYLOAD:', payload);
    //const id = payload
    const res = yield call(request, {
      url: `${BASE_URL}/${payload}`,
      method: 'delete'
    });
    if (res) {
      yield put(enqueueSnackbar({ message: res.data.message }));
      yield put(getCatalogs());
      //yield put(push(routes.CMS_CATALOGS));
    }
  } catch (error) {}
}

export default function* catalogsSaga() {
  yield takeLatest(types.GET_CATALOGS, fetchCatalogs);
  yield takeLatest(types.CREATE_CATALOG_REQUEST, createCatalog);
  yield takeLatest(types.DELETE_CATALOG_REQUEST, deleteCatalog);
}
