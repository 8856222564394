import { defineMessages } from 'react-intl';

export const scope = 'cms_catalogs';

export default defineMessages({
  add_catalog: {
    id: `${scope}.add_catalog`,
    defaultMessage: 'Dodaj katalog'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Ime'
  },
  choose_photo: {
    id: `${scope}.choose_photo`,
    defaultMessage: 'Izaberi sliku'
  },
  choose_pdf: {
    id: `${scope}.choose_pdf`,
    defaultMessage: 'Izaberi PDF fajl'
  }
});
