import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCatalogs = state => state.catalogs || initialState;

export const makeSelectCatalogs = () =>
  createSelector(selectCatalogs, substate => substate.catalogs);

export const makeSelectCreateCatalogtIsPending = () =>
  createSelector(selectCatalogs, substate => substate.createProductIsPending);
