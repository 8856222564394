import React, { useCallback, useEffect } from 'react';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCatalog, getCatalogs } from './redux/actions';
import { makeSelectCatalogs } from './redux/selectors';
import LinkButton from 'cms/components/Table/LinkButton';
import messages from './messages';
import routes from 'routes';
import { AiOutlineDelete } from 'react-icons/ai';

const key = 'catalogs';

const Catalogs = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const catalogs = useSelector(makeSelectCatalogs());

  const { data } = catalogs;

  console.log('CATALOGS ARE: ', data);

  useEffect(() => {
    dispatch(getCatalogs());
  }, [dispatch]);

  const handleDelete = useCallback(
    id => {
      return () => {
        console.log('ARGS ARE: ', id);
        dispatch(deleteCatalog(id));
      };
    },
    [dispatch]
  );

  return (
    <div className="catalogsPage">
      <LinkButton label={messages.add_catalog} link={routes.CMS_ADD_CATALOG} />
      <div className="data">
        {data &&
          data.map(catalog => (
            <div className="catalog" key={catalog.id}>
              <p>{catalog.name}</p>
              <AiOutlineDelete onClick={handleDelete(catalog.id)} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Catalogs;
