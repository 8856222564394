import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import messages from 'messages';

const Buttons = ({ label, isPending, handleSaveParams }) => {
  const { formatMessage } = useIntl();
  const { goBack } = useHistory();

  const handleClick = () => {
    if (handleSaveParams) handleSaveParams(true);
    goBack();
  };

  return (
    <div className="form_buttons_group jc_end">
      <Button className="button default" onClick={handleClick}>
        {formatMessage(messages.back_link)}
      </Button>
      <Button type="submit" className="button ml_20" disabled={isPending}>
        {formatMessage(label)}
      </Button>
    </div>
  );
};

export default Buttons;
