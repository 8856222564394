import types from './constants';

export const getCatalogs = payload => ({
  type: types.GET_CATALOGS,
  payload
});

export const setCatalogs = payload => ({
  type: types.SET_CATALOGS,
  payload
});

export const createCatalog = (payload, setErrors) => ({
  type: types.CREATE_CATALOG_REQUEST,
  payload,
  meta: { setErrors }
});

export const createCatalogSuccess = () => ({
  type: types.CREATE_CATALOG_SUCCESS
});

export const createCatalogErrors = () => ({
  type: types.CREATE_CATALOG_ERROR
});

// export const sendPdfSuccess = (id, payload, method) => ({
//   type: types.SEND_PDF_REQUEST,
//   id,
//   payload,
//   method
// });

// export const createMainPhoto = (id, photo, method) => ({
//   type: types.CREATE_MAIN_PHOTO,
//   id,
//   photo,
//   method
// });

// export const removePhoto = (product_id, img_id) => ({
//   type: types.REMOVE_PHOTO,
//   product_id,
//   img_id
// });

export const deleteCatalog = payload => ({
  type: types.DELETE_CATALOG_REQUEST,
  payload
});
