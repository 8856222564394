import produce from 'immer';
import types from './constants';

export const initialState = {
  catalogs: [],
  createCatalogIsPending: false
};
/* eslint-disable default-case */
const catalogsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_CATALOGS:
        draft.catalogs = action.payload;
        break;
      case types.CREATE_CATALOG_REQUEST:
        draft.createCatalogIsPending = true;
        break;
      case types.CREATE_CATALOG_SUCCESS:
      case types.CREATE_CATALOG_ERROR:
        draft.createCatalogIsPending = false;
        break;
    }
  });

export default catalogsReducer;
