const CATALOGS = '[CMS_CATALOGS]';

const types = {
  GET_CATALOGS: `${CATALOGS} GET_CATALOGS`,
  SET_CATALOGS: `${CATALOGS} SET_CATALOGS`,

  CREATE_CATALOG_REQUEST: `${CATALOGS} CREATE_CATALOG_REQUEST`,
  CREATE_CATALOG_SUCCESS: `${CATALOGS} CREATE_CATALOG_SUCCESS`,
  CREATE_CATALOG_ERROR: `${CATALOGS} CREATE_CATALOG_ERROR`,

  SEND_PDF_REQUEST: `${CATALOGS} SEND_PDF_REQUEST`,
  CREATE_MAIN_PHOTO: `${CATALOGS} CREATE_MAIN_PHOTO`,

  REMOVE_PHOTO: `${CATALOGS} REMOVE_PHOTO`,
  DELETE_CATALOG_REQUEST: `${CATALOGS} DELETE_CATALOG_REQUEST`
};

export default types;
